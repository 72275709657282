import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}></MDXTag>

export const _frontmatter = {"templateKey":"content-pages","title":"How do we write in the digital age?","key":"how-do-we-write-in-the-digital-age","parentKey":"writing-involves-choices"};

  